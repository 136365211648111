import { useUnregisteredChildPaths } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useUnregisteredChildPaths';
import { useFormContext, useFormState } from 'react-hook-form';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useFieldStateWithUnregisteredChildren(path: string) {
    /***** HOOKS *****/
    const { getFieldState } = useFormContext();
    const formState = useFormState();
    const fieldState = getFieldState(path, formState);

    /***** RENDER HELPERS *****/
    const fieldStates = useUnregisteredChildPaths().map((fieldPath) => getFieldState(fieldPath, formState));
    const allFieldStates = [...fieldStates, fieldState].filter((state) => typeof state.error?.message === 'string');
    return allFieldStates[0] || { isTouched: false, error: undefined };
}
